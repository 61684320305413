import React, { useState, useEffect } from "react";
import axios from "axios";
import classnames from "classnames";

import { Card } from "../Card";
import Timeline from "../Timeline";
import Preloader from "../../Preloader";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "./mainpage.scss";

export const Mainpage = () => {
    const [type, setType] = useState("news");
    const [news, setNews] = useState({});
    const [list, setList] = useState([]);
    const [isFetched, setFetched] = useState(false);
    const [articlesOffset, setArticlesOffset] = useState(1);
    const [articlesFetching, setArticlesFetching] = useState(false);
    const [emptyArticles, setemptyArticles] = useState(false);
    const [timelineOffset, setTimelineOffset] = useState(1);

    useEffect(() => {
        axios.post(`/getMainPage`, {}).then(function (response) {
            setNews(response.data);
            setFetched(true);
        });
        // axios.post(`/getArticleByText`, {
        //     limit: 7,
        //     offset: 0,
        //     text: ''
        // }).then(function (response) {
        //     setList(response.data.articles);
        // });

        // document.addEventListener('scroll', scrollHandler)
        // return function () {
        //     document.removeEventListener('scroll', scrollHandler)
        // }
    }, []);

    // useEffect(() => {
    //     if (articlesFetching) {
    //         axios.post(`/getArticleByText`, {
    //             offset: articlesOffset,
    //             limit: 7,
    //             text: ''
    //         })
    //         .then(response => {
    //             if (response.data.articles.length) {
    //                 setList([...list, ...response.data.articles]);
    //                 setArticlesOffset(prevState => prevState + 1)
    //             } else {
    //                 setemptyArticles(true);
    //             }
    //         })
    //         .finally(() => setArticlesFetching(false))
    //     }
    // }, [articlesFetching]);

    useEffect(() => {
        if (articlesFetching) {
            setTimelineOffset((prevState) => prevState + 1);
        }
    }, [articlesFetching]);

    useEffect(() => {
        if (type === "timeline") {
            document.addEventListener("scroll", scrollHandler);
            return function () {
                document.removeEventListener("scroll", scrollHandler);
            };
        }
    }, [type]);

    const scrollHandler = (e) => {
        const footerHeight = document.querySelector('footer').scrollHeight;
        if ((e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) - footerHeight) < 300 && !emptyArticles) {
            setArticlesFetching(true);
        }
    }

    return (
        <div className="mainpage">
            <div className="container">
                <div className={classnames("mainpage__content", {
                        mainpage__news: type === "news",
                        mainpage__timeline: type === "timeline",
                })}>
                    <main>
                        <div className="mainpage__type_switch">
                            <div
                                className={classnames("mainpage__type_switch_btn news_btn", {
                                    mainpage__type_switch_btn_active: type === "news",
                                })}
                                onClick={() => {
                                    setType("news");
                                }}
                            >
                                <p>Alle Nachrichten</p>
                            </div>
                            <div
                                className={classnames("mainpage__type_switch_btn timeline_btn", {
                                    mainpage__type_switch_btn_active: type === "timeline",
                                })}
                                onClick={() => {
                                    setType("timeline");
                                }}
                            >
                                <p>Newsline</p>
                            </div>
                        </div>
                        {isFetched ? (
                            <div className="mainpage__type_news_content">
                                    <section className="mainpage__main_news mainpage__bordered_block">
                                    {news.main && news.main[0] && (
                                        <Card article={news.main[0]} type="big" titlePosition="top" />
                                    )}
                                    {Boolean(news.actual.length) && (
                                        <section className="mainpage__actual">
                                            <header className="mainpage__title">
                                                <h3>Aktuell</h3>
                                                <div className="mainpage__slider_nav">
                                                    <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_prev mainapge__actual_prev" name="prev">
                                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                                fill="#222222"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_next mainapge__actual_next" name="next">
                                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                                fill="#222222"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </header>
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={"auto"}
                                                modules={[Navigation]}
                                                navigation={{
                                                    nextEl: ".mainapge__actual_next",
                                                    prevEl: ".mainapge__actual_prev",
                                                }}
                                                className="mainpage__actual_slider"
                                            >
                                                {news.actual.map((article, i) => (
                                                    <SwiperSlide>
                                                        <Card key={i} article={article} type="actual" titlePosition="top"></Card>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </section>
                                    )}
                                    </section>
                                {news.categories?.map((category) => {
                                    return <section>
                                        <h3 className="mainpage__category_name">{category.name}</h3>
                                        <div className="mainpage__news_list">
                                            {category.articles?.map((article) => {
                                                return <Card article={article} type="medium" />
                                            })}
                                        </div>
                                    </section>
                                })}
                                {/* <section className="mainpage__news_list">
                                    { 
                                        list.length > 0 && list.map((article) => {
                                            return <Card article={article} type="medium" />
                                        })
                                    }
                                </section> */}
                            </div>

                        ) : (
                            <Preloader></Preloader>
                        )}
                    </main>
                    <Timeline page={timelineOffset} setArticlesFetching={setArticlesFetching} type={type}></Timeline>
                </div>
            </div>
        </div>
    );
};