import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Header } from './components/Header'
import { Footer } from "./components/Footer";
import { Mainpage } from "./components/News/Mainpage";
import { Article } from './components/News/Article/index';
import { Search } from "./components/News/Search";
import { Profile } from './components/Profile';
import NotFound from "./components/NotFound";
import AboutUs from "./components/AboutUs";
import Contacts from "./components/Contacts";
import CookieWraning from "./components/CookieWarning";

function App() {
  const pathname = window.location.href
  return (
    <div className="App">
      <Router>
        <div>
          <Header></Header>
          <Routes>
            <Route path="/" element={<Mainpage />}></Route>
            <Route path="/category/:content" element={<Search />}></Route>
            <Route path="/tag/:content" element={<Search />}></Route>
            <Route path="/author/:author" element={<Search />}></Route>
            <Route path="/search" element={<Search />}></Route>
            <Route path="/read/:date/:id" element={<Article />}></Route>
            <Route path='/profile' element={<Profile />}></Route>
            <Route path="*" element={<NotFound />} status={404}></Route>
            <Route path="/datenschutz" element={<AboutUs type="privacy" />}></Route>
            <Route path="/cookies" element={<AboutUs type='cookies'/>}></Route>
            <Route path="/impressum" element={<AboutUs type="data" />}></Route>
            <Route path="/kontact" element={<Contacts />}></Route>
          </Routes>
          {localStorage.getItem('cw') != '1' && !decodeURI(pathname).includes('cookies') && !decodeURI(pathname).includes('datenschutz') && <CookieWraning></CookieWraning>}
          <Footer></Footer>
        </div>
      </Router>
    </div>
  );
}

export default App;
