import "./footer.scss";
import { useState, useEffect } from "react";
import Modal from "../Header/ui/modal";
import axios from "axios";

export const Footer = ({tags = ["Politik", "Wirtschaft", "Wissenschaft", "Panorama"]}) => {
    const [newsModalActive, setNewsModalActive] = useState(false);
    const [contacts, setContacts] = useState({})

    useEffect(() => {
        axios.post("/getInfo", {}).then((data) => {
            setContacts(data.data);
        });
    }, [])

    return (
        <footer className="footer">
            <Modal open={newsModalActive} setOpen={setNewsModalActive} exit={"news"}></Modal>
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__main_content">
                        <div className="footer__links">
                            <div className="header__logo" style={{'textAlign':"center"}}>
                                <a href="/">
                                    <img src="/logo-white.svg" alt="" />
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" viewBox="0 0 180 39" role="img"><path d="M17.19.5v4.39h-5.87V38.5H6.13V4.89H0V.5Zm19.37 0v4.39h-8.81v11.94h7.87v4.38h-7.87v12.9h8.81v4.39h-14V.5Zm12.77 0v33.61h8.76v4.39h-14V.5Zm27.94 0v4.39h-8.81v11.94h7.87v4.38h-7.87v12.9h8.81v4.39h-14V.5Zm8.27 38V.5h6.19c4.44 0 10.11.4 10.11 10.18 0 7.71-3.1 11.29-11.15 11V38.5Zm5.15-33.71v12.6c4.44.3 5.68-2.07 5.68-6.3 0-3.43-1-6.3-5-6.3ZM127 19.5c0 6.85-.1 19.5-10.21 19.5s-10.21-12.65-10.21-19.5S106.69 0 116.8 0 127 12.65 127 19.5Zm-14.89 0c0 8.06.57 14.76 4.69 14.76s4.69-6.7 4.69-14.76-.57-14.76-4.69-14.76-4.69 6.7-4.69 14.76Zm26.6-19v33.61h8.76v4.39h-14V.5Zm18.46 38h-5.22V.5h5.22m20.92 5.84a7.21 7.21 0 0 0-4.4-1.61 4.61 4.61 0 0 0-4.6 4.89 6.05 6.05 0 0 0 1.45 4.13l3.93 4.18c3.52 3.48 5.53 6 5.53 11.14 0 5.34-3.41 9.93-9.15 9.93a9.21 9.21 0 0 1-5.69-1.81V32.1a8.57 8.57 0 0 0 5.12 2c3.21 0 4.55-2.47 4.55-5.29 0-7.56-10.91-9.07-10.91-19 0-5.34 2.9-9.72 8.74-9.72a10.71 10.71 0 0 1 5.48 1.26Z" fill="#b42900"/></svg> */}
                                    {/* <span>NEWS</span> */}
                                </a>
                            </div>
                            <ul className="footer__links_list">
                                <li>
                                    <h6>Über uns</h6>
                                </li>
                                <li>
                                    <ul>
                                        <li className="footer__links_item">
                                            <a href="/impressum">Impressum</a>
                                        </li>
                                        <li className="footer__links_item">
                                            <a href="/kontact">Kontact</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="footer__links_list">
                                <li>
                                    <h6>Kategorien</h6>
                                </li>
                                <li>
                                    <ul>
                                        {tags.map((tag, i) => {
                                            return (
                                                <li key={i} className="footer__links_item">
                                                    <a href={`/category/${tag}`}>{tag}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            </ul>
                            <ul className="footer__links_list">
                                <li>
                                    <h6>Rechtsinformation</h6>
                                </li>
                                <li>
                                    <ul>
                                        
                                        <li className="footer__links_item">
                                            <a href="/datenschutz">Datenschutz</a>
                                        </li>
                                        <li className="footer__links_item">
                                            <a href="/cookies">Cookies & Tracking</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="footer__links_list">
                                <li className="footer__links_item">
                                    <a
                                    onClick={() => setNewsModalActive(true)}
                                    >Verbinde dich mit uns</a>
                                </li>
                                <ul className="header__links socials" style={{'alignItems':"center"}}>
                                    {
                                        contacts.fb && <li>
                                        <a href={contacts.fb} className="header__link fb">
                                            <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin"><path fill="#fff" d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759H734V905H479V609h255V391q0-186 104-288.5T1115 0q147 0 228 12z"></path></svg>                                        
                                        </a>
                                    </li>
                                    }
                                    {
                                        contacts.tw && <li>
                                        <a href={contacts.tw} className="header__link tw">
                                            <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin"><path fill="#fff" d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5T1369.5 1125 1185 1335.5t-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5T285 1033q33 5 61 5 43 0 85-11-112-23-185.5-111.5T172 710v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5T884 653q-8-38-8-74 0-134 94.5-228.5T1199 256q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"></path></svg>
                                        </a>
                                    </li>
                                    }
                                    {
                                        contacts.tg && <li>
                                        <a href={contacts.tg} className="header__link tg">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 50 50" width="50px" height="50px"><path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445 c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758 c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125 c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077 C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"/></svg>
                                        </a>
                                    </li>
                                    }
                                    {
                                        contacts.email && <li>
                                        <a href={`mailto:${contacts.email}`} className="header__link m">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1998 1200">
                                            <path d="M0 0v1200h1998V0H0zm1819.39 100L996.11 762.72 178.61 100h1640.78zM100 1100V165l895.89 726.27L1898 165.1V1100H100z" fill="#fff"></path></svg>                                    </a>
                                    </li>
                                    }
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer__licence">Copyright © 2023</div>
            </div>
        </footer>
    );
};