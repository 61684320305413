import { React, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./index.scss";

const Img = ({ author, photoUrl }) => {
    function createMarkup(str) {
        return { __html: str };
    }

    let div = document.createElement("div");
    div.innerHTML = author;
    let div2 = document.createElement("div")
    div2.innerHTML = div.textContent
    author = "Bild: " + div2.textContent;
    div.remove();
    div2.remove();

    const webpImg = photoUrl?.split(".")[0] + ".webp";
    const [imgChecked, setImgChecked] = useState(false);
    return (
        <figure>
            <picture className="img-container">
                <source srcSet={webpImg} alt={author} type="image/webp" />
                <img
                    src={photoUrl?.split('.')[1] === 'png' ? photoUrl?.split('.')[0] + '.jpg' : photoUrl}
                    alt={author}
                    className="img-item"
                    onError={(e) => {
                        if (!imgChecked) {
                            setImgChecked(true);
                            e.target.parentNode.children[0].srcset = photoUrl?.split('.')[1] === 'png' ? photoUrl?.split('.')[0] + '.jpg' : photoUrl;
                        }
                    }}
                />
            </picture>
            {Boolean(author.length) && <figcaption className="img-description">{author}</figcaption>}
        </figure>
    );
};

export default Img;
