import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import axios from "axios";
import moment from 'moment';
import 'moment/locale/de'
import {Helmet} from "react-helmet";
import { prepareDate } from "../../../utils/prepareDate";

import "./article.scss";
import Photo from "../../Photo";
import Timeline from "../Timeline";
import { Card } from "../Card";
import { Comment } from './Comment';
import Modal from "../../Header/ui/modal";
import Preloader from "../../Preloader";
import NotFound from '../../NotFound';
import QuoteImg from "./img";

import Plyr from "plyr-react"
import "plyr-react/plyr.css"

export const Article = () => {
    const [article, setArticle] = useState({});
    const [isFetched, setFetched] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [formActive, setFormActive] = useState(false);
    const [user, setUser] = useState({});
    const [popular, setPopular] = useState([]);
    const [mostCommented, setMostCommented] = useState([]);
    const [comments, setComments] = useState([]);
    const [commentsOffset, setCommentsOffset] = useState(2);
    const [commentsFetching, setCommentsFetching] = useState(false);
    const [emptyComments, setemptyComments] = useState(false);
    const [replyId, setReplyId] = useState();
    const [copied, setCopied] = useState();

    const [activeStickers, setActiveStickers] = useState([]);
    const [stickersCount, setStickersCount] = useState({});

    const params = useParams();

    useEffect(() => {
        const id = window.location.hash
        const comment = document.getElementById(id.slice(1, id.length));
        comment?.scrollIntoView({behavior: 'smooth'})
    }, [article])

    useEffect(() => {
        axios.post("/getArticle", {id: params.id})
        .then(function (response) {
            const articleData = response.data.article;
            articleData.data = JSON.parse(articleData.data);
            articleData.createdAt = prepareDate(articleData.publishDate);

            let stickers = [];
            response.data.mySticker?.map((sticker) => {
                stickers.push(sticker.StickerId);
            });

            articleData.data.blocks?.map(block => {
                if (block.type === 'paragraph') {
                    block.data.text = prepareLinks(block.data.text);
                }
            })
            articleData.data.blocks?.forEach((block) => {
                if ((block.type === "image" && !articleData.PreviewPhoto)) {
                    articleData.PreviewPhoto = block.data?.file?.url;
                }
            });
            if (!articleData.PreviewPhoto) {
                articleData.data.blocks?.forEach((block) => {
                    if ((block.type === "carousel" && !articleData.PreviewPhoto)) {
                        articleData.PreviewPhoto = block.data[0]?.url;
                    }
                });
            }
            setArticle(articleData);
            setFetched(true);
            setComments(articleData.Comments);

            setActiveStickers(stickers);
            setStickersCount(response.data.count);
        })
        .catch((e) => {
            setFetched(true);
        })
        axios.post("/getActual", {offset: 0, limit: 6})
        .then(function (response) {
            setPopular(response.data);
        })
        axios.post("/getMostCommented", {offset: 0, limit: 5})
        .then(function (response) {
            setMostCommented(response.data);
        })
    }, []);

    // useEffect(() => {
    //     if (commentsFetching) {
    //         axios.post('/getArticleComment', {
    //             id: params.id,
    //             offset: commentsOffset,
    //             limit: 10
    //         })
    //         .then(response => {
    //             if (response.data.length) {
    //                 setComments([...comments, ...response.data]);
    //                 setCommentsOffset(prevState => prevState + 1)
    //             } else {
    //                 setemptyComments(true);
    //             }
    //         })
    //         .finally(() => setCommentsFetching(false))
    //     }
    // }, [commentsFetching])

    const handleVideoClick = (event) => {
        let video = event.target.closest(".article__video").querySelector("video");
        let btn = event.target.closest(".article__video_btn");
        btn.style.display = "none";
        video.setAttribute("controls", "controls");
        video.play();
    };

    let location = window.location;
    const copyLink = (e) => {
        let link = e.target.closest(".article__socials_copy").querySelector("input");
        link.select();
        document.execCommand('copy');
        navigator.clipboard.writeText(link.value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2500);
    };

    function createMarkup(str) {
        return { __html: str };
    }

    function prepareLinks(text) {
        let div = document.createElement('div');
        div.innerHTML = text;
        div.querySelectorAll('.article__link').forEach(block => {
            let a = document.createElement('a');
            const text = block.textContent;
            a.textContent = text
            if (text[0] === "#") {
                a.setAttribute('href', `/tag/${text.slice(1, text.length)}`);
            } else {
                a.setAttribute('href', `/search?text=${text}`);
            }
            a.classList.add('article__link');
            block.insertAdjacentElement('afterend', a);
            block.remove();
        });
        return div.innerHTML
    }

    useEffect(() => {
        axios.post(`/getMe`, {}, { withCredentials: true }).then(function (response) {
            if (response.data.id) {
                setLoggedIn(true);
                setUser(response.data);
            }
        });
    }, []);

    const leaveComment = (event) => {
        let data = Object.fromEntries(new FormData(event.target).entries());
        event.target.text.value = '';
        const text = data.text
        if (data.text.length) {
            const newComment = {
                text: text,
                createdAt: new Date(),
                User: user,
                noReply: true,
            }
            axios.post(`/leaveComment`, {
                ArticleId: params.id,
                text: data.text,
            });
            setComments((prev) => [...prev, newComment])
        }
    };

    const submitCommentForm = (e) => {
        e = e || window.event;
        if (e.keyCode === 13) {
            e.preventDefault();
            const submit = e.target.closest('form').querySelector('input');
            submit.click();
            e.target.value = '';
        }
    }

    const leaveSticker = (id) => {
        if (!isLoggedIn) {
            setFormActive(true);
            return
        }
        let stickers = activeStickers;
        if (activeStickers.includes(id)) {
            stickers = activeStickers.filter((sticker) => sticker != id);
            setStickersCount({...stickersCount, [`${id}`]: stickersCount[`${id}`] - 1});
        } else {
            stickers.push(id);
            if (stickersCount[`${id}`]) {
                setStickersCount({...stickersCount, [`${id}`]: stickersCount[`${id}`] + 1});
            } else {
                setStickersCount({...stickersCount, [`${id}`]: 1});
            }
        }
        if (id === 1 && stickers.includes(2)) {
            stickers = stickers.filter((sticker) => sticker != 2);
            setStickersCount({["1"]: (stickersCount["1"] || 0) + 1, ["2"]: stickersCount["2"] - 1});
            axios.post(`/leaveSticker`, {
                ArticleId: params.id,
                stickerId: 2,
            });
        } 
        if (id === 2 && stickers.includes(1)) {
            stickers = stickers.filter((sticker) => sticker != 1);
            setStickersCount({["2"]: (stickersCount["2"] || 0) + 1, ["1"]: stickersCount["1"] - 1});
            axios.post(`/leaveSticker`, {
                ArticleId: params.id,
                stickerId: 1,
            });
        }
        setActiveStickers([...stickers]);
        axios.post(`/leaveSticker`, {
            ArticleId: params.id,
            stickerId: id,
        });
    };

    moment.locale('de')

    return !isFetched || article.title ? (
        <div className="article" itemScope itemType="https://schema.org/Article">
            {copied && <div className="copied">Kopiert</div>}
            <Helmet>
                <title>{`${article?.title?.slice(0, 60)} - ${moment(article.createdAt?.slice(6, article.createdAt.length), 'DDMMYYYY').format('LL').slice(0, -2)}`}</title>
                <meta name="description" content={`${article?.text?.slice(0, 110)}`} />
                <meta property="title" content={article?.title}  />
                <meta property="og:title" content={article?.title}  />
                <meta property="og:description" content={`${article?.text?.slice(0, 110)}`}  />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:locale" content="de_DE"/>
                <meta property="og:site_name" content={window.location.hostname} />
                <meta property="og:image:width" content="620" />
                <meta property="og:image:height" content="460" />
                <meta property="og:image" content={`${window.location.origin}${article?.PreviewPhoto}`} />

                <link itemProp="image" href={`${window.location.origin}${article?.PreviewPhoto}`}/>
                <meta itemProp="headline name" content={article?.title}/>
                <meta itemProp="description" content={`${article?.text?.slice(0, 110)}`}/>
                <meta itemProp="datePublished" datetime={moment(article?.publishDate).format('YYYY-MM-DD')} content={moment(article?.publishDate).format('YYYY-MM-DD')}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:description" content={`${article?.text?.slice(0, 110)}`} />
                <meta property="twitter:image" content={`${window.location.origin}${article?.PreviewPhoto}`}/>
                <meta property="twitter:title" content={article?.title}/>
            </Helmet>
            {isFetched ? (
                <div className="container">
                    <ul className="article__breadcumbs">
                        <li><a href="/">Berlin 24/7</a></li>
                        <li><a href={`/category/${article.Category?.name}`}>{article.Category?.name}</a></li>
                        <li><div>{article.title}</div></li>
                    </ul>
                    <div className="article__content">
                        <div className="article__actual">
                            <div className="article__actual_content">
                                {
                                    !!mostCommented.length && 
                                    <div className="article__actual_item">
                                        <div className="timeline__head">
                                            <div className="timeline__head_text">MEISTKOMMENTIERT</div>
                                        </div>
                                        <div className="article__actual_item_content">
                                            {mostCommented.map((article, i) => {
                                                return <Card key={i} article={article} type="only-title"></Card>
                                            })}
                                        </div>
                                    </div>
                                }
                                {
                                    !!popular.length &&
                                    <div className="article__actual_item">
                                        <div className="timeline__head">
                                            <div className="timeline__head_text">MEISTGELESEN</div>
                                        </div>
                                        <div className="article__actual_item_content">
                                            {popular.map((article, i) => {
                                                return <Card key={i} article={article} type="only-title"></Card>
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="article__container">
                            <article itemProp="articleBody">
                                <h1>{article.title}</h1>
                                <div className="article__small_info">
                                    <div className="article__category_and_time">
                                        <div className="article__time">{article.createdAt}  -</div>
                                        <a href={`/tag/${article.Category?.name}`} className="article__category">
                                            {article.Category?.name}
                                        </a>
                                    </div>
                                </div>
                                {article?.data?.blocks?.map((block) => {
                                    switch (block.type) {
                                        case "header": {
                                            switch (block.data.level) {
                                                case 2: {
                                                    return <h2>{block.data.text}</h2>;
                                                }
                                                case 3: {
                                                    return <h3>{block.data.text}</h3>;
                                                }
                                                case 4: {
                                                    return <h4>{block.data.text}</h4>;
                                                }
                                                case 5: {
                                                    return <h5>{block.data.text}</h5>;
                                                }
                                                case 6: {
                                                    return <h6>{block.data.text}</h6>;
                                                }
                                                default: {}
                                            }
                                            break;
                                        }
                                        case "image": {
                                            return <Photo author={block.data.caption} photoUrl={block.data.file.url}></Photo>;
                                        }
                                        case "colored": {
                                            return (
                                                <div className="article__colored">
                                                    <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>
                                                </div>
                                            );
                                        }
                                        case "quote": {
                                            return (
                                                <div className="article__quote">
                                                    <div className="article__quote_text">
                                                        <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.description)}></p>
                                                    </div>
                                                    <a href={`/tag/`} className="article__author">
                                                        {/* <QuoteImg photoUrl={block.data.photo || "/uploads/default_profile.png"} name={block.data.name}/> */}
                                                        <p className="article__author_name">{block.data.name}</p>
                                                    </a>
                                                </div>
                                            );
                                        }
                                        case "list": {
                                            if (block.data.style === "ordered") {
                                                return (
                                                    <ol className="article__numbered_list">
                                                        {block.data.items.map((item) => {
                                                            return (
                                                                <li>
                                                                    {item.content}
                                                                    {item.items.map((item) => {
                                                                        return (
                                                                            <ol>
                                                                                <li>
                                                                                    {item.content}
                                                                                    {item.items.map((item) => {
                                                                                        return (
                                                                                            <ol>
                                                                                                <li>{item.content}</li>
                                                                                            </ol>
                                                                                        );
                                                                                    })}
                                                                                </li>
                                                                            </ol>
                                                                        );
                                                                    })}
                                                                </li>
                                                            );
                                                        })}
                                                    </ol>
                                                );
                                            } else {
                                                return (
                                                    <ul className="article__bulleted_list">
                                                        {block.data.items.map((item) => {
                                                            return (
                                                                <li>
                                                                    {item.content}
                                                                    {item.items.map((item) => {
                                                                        return (
                                                                            <ul>
                                                                                <li>{item.content}</li>
                                                                            </ul>
                                                                        );
                                                                    })}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                );
                                            }
                                        }
                                        case "carousel": {
                                            return (
                                                <div className="article__slider">
                                                    <button className="article__slider_nav_btn article__slider_nav_btn_prev article__slider_prev">
                                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                                fill="#222222"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <Swiper
                                                        spaceBetween={20}
                                                        slidesPerView={"auto"}
                                                        modules={[Navigation]}
                                                        navigation={{
                                                            nextEl: ".article__slider_next",
                                                            prevEl: ".article__slider_prev",
                                                        }}
                                                        className="artilce__swiper"
                                                    >
                                                        {block.data.map((img) => {
                                                            return (
                                                                <SwiperSlide>
                                                                    <Photo author={img.caption} photoUrl={img.url}></Photo>
                                                                </SwiperSlide>
                                                            );
                                                        })}
                                                    </Swiper>
                                                    <button className="article__slider_nav_btn article__slider_nav_btn_next article__slider_next">
                                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                                fill="#222222"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            );
                                        }
                                        case "paragraph": {
                                            return <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>;
                                        }
                                        case "videos": {
                                            return <Plyr source={{
                                                type: "video",
                                                sources: block.data.map((v) => {
                                                    return {
                                                        src: v.url,
                                                        size: v.caption,
                                                    }
                                                })
                                            }} />
                                            // <div className="article__video">
                                            //             <figure>
                                            //                 <p className='img-container'>
                                            //                     <video className='article__video' src={block.data.file.url + "#t=0.001"} poster></video>
                                            //                     <div className="article__video_btn" onClick={(e) => {
                                            //                         handleVideoClick(e);
                                            //                     }}>
                                            //                         <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            //                             <path d="M0.333344 38.5301V3.47006C0.33317 2.88355 0.487752 2.30737 0.781483 1.79971C1.07521 1.29205 1.49769 0.870881 2.00626 0.578724C2.51483 0.286568 3.09148 0.13377 3.67799 0.135761C4.2645 0.137752 4.8401 0.294461 5.34668 0.590063L35.3967 18.1234C35.8995 18.4171 36.3166 18.8373 36.6065 19.3423C36.8963 19.8473 37.0489 20.4194 37.0489 21.0017C37.0489 21.584 36.8963 22.1561 36.6065 22.6611C36.3166 23.1661 35.8995 23.5864 35.3967 23.8801L5.34668 41.4101C4.8401 41.7057 4.2645 41.8624 3.67799 41.8644C3.09148 41.8664 2.51483 41.7136 2.00626 41.4214C1.49769 41.1292 1.07521 40.7081 0.781483 40.2004C0.487752 39.6928 0.33317 39.1166 0.333344 38.5301Z" fill="white"/>
                                            //                         </svg>
                                            //                     </div>
                                            //                 </p>
                                            //                 {
                                            //                     Boolean(block.data.caption.length) &&
                                            //                     <figcaption className='img-description'>Video: {block.data.caption}</figcaption>
                                            //                 }
                                            //             </figure>
                                            //         </div>
                                        }
                                        case "videoLink": {
                                            return <Plyr source={{
                                                type: "video",
                                                sources: [
                                                  {
                                                    src: block.data.data.file.url,
                                                    size: 720,
                                                  },
                                                  {
                                                    src: block.data.data.file.url,
                                                    size: 1080,
                                                  }
                                                ]
                                              }} />
                                        }
                                        case "embed": {    
                                            switch(block.data.service) {
                                                case 'youtube': {
                                                    return <div className="article__video">
                                                        <iframe 
                                                            style={{"aspectRatio":"16/9"}}
                                                            width="100%" 
                                                            src={block.data.embed} 
                                                            title="" 
                                                            frameborder="0" 
                                                            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                        >
                                                        </iframe>
                                                    </div>
                                                }
                                                case 'instagram': {
                                                    return <iframe 
                                                    style={{
                                                        "aspectRatio":"9/16",
                                                        "max-width":"400px"
                                                    }}
                                                    width="100%" 
                                                    src={block.data.embed} 
                                                    title="" 
                                                    frameborder="0" 
                                                    allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                >
                                                </iframe>
                                                }
                                                case 'facebook': {
                                                    return <iframe 
                                                        style={{
                                                            "aspectRatio":"9/16",
                                                            "max-width":"500px"
                                                        }}
                                                        // width="100%" 
                                                        src={block.data.embed} 
                                                        frameborder="0" 
                                                        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                    >
                                                    </iframe>
                                                }
                                                case 'twitter': {
                                                    return <iframe 
                                                    width="100%" 
                                                    height='400'
                                                    src={block.data.embed} 
                                                    frameborder="0" 
                                                    allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                >
                                                </iframe>
                                                }
                                                case 'vimeo': {
                                                    return <div className="article__video">
                                                        <iframe width="100%;" style={{"aspectRatio":"16/9"}} frameborder="0" src={block.data.embed} class="embed-tool__content" allow="fullscreen"></iframe>
                                                    </div>
                                                }
                                                case 'odysee': {
                                                    return <div className="article__video">
                                                        <iframe width="100%;" style={{"aspectRatio":"16/9"}} frameborder="0" src={block.data.embed} class="embed-tool__content" allow="fullscreen"></iframe>
                                                    </div>
                                                }
                                            }                                      
                                        }
                                        default:
                                    }
                                })}
                            </article>
                            { article.is_paid &&
                                <div className="article__paid">
                                    <div className="article__paid_text">
                                    Abonnieren Sie news.de, um diesen Artikel weiterzulesen und mehr Inhalte zu erhalten
                                    </div>
                                    <div className="article__paid_btn">Jetzt abonnieren</div>
                                </div>
                            }
                            { Boolean(article.Article_tags.length) &&
                            <div className="article__tags">
                                <div className="article__tags_head">
                                    MEHR ZUM THEMA
                                </div>
                                    <ul className="article__tags_list">
                                        {article.Article_tags.map((tag) => (
                                            <li>
                                                <a href={`/tag/${tag.name}`} className="article__tag">
                                                    {tag.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                            </div>
                            }
                            { Boolean(article.Article_authors.length) && ![2, 3, 4].includes(article.Article_authors[0].user_type) &&
                                <div className="article__authors">
                                    <div className="article__authors_title">
                                        <div className="article__tags_head">
                                            Der Autor
                                        </div>
                                    </div>
                                    <ul className="article__authors_list">
                                        {article.Article_authors.map((author) => (
                                            <li>
                                                <a href={`/author/${author.id}`} className="article__author">
                                                    <div className="article__author_photo">
                                                        <img src={`/uploads/${author.photo || 'default_profile.png'}`}alt={author.name} />
                                                    </div>
                                                    <div className="article__author_name" itemprop="author">
                                                        {author.name} {author.lastname}
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            <div className="article__socials">
                                <div className="article__tags_head">
                                    TEILE DIESEN BEITRAG
                                </div>
                                <ul className="article__socials_list">
                                    <li className="footer__link_social footer__link_social_gp">
                                        <noindex>
                                            <a target="_blank" rel='nofollow' href={`https://getpocket.com/save?url=${window.location}`}>
                                                <svg id="icon-pocket" xmlns="http://www.w3.org/2000/svg" class="share-link__icon share-link__icon--$share_link_service" viewBox="0 0 24 24">
                                                <path id="pocket" d="M3.75,2A2.76,2.76,0,0,0,1,4.75v6.42a11,11,0,0,0,22,0V4.75A2.76,2.76,0,0,0,20.25,2Zm0,1.83h16.5a.91.91,0,0,1,.92.92v6.42a9.17,9.17,0,0,1-18.34,0V4.75A.91.91,0,0,1,3.75,3.83Zm3.35,4a1.4,1.4,0,0,0-1,.43,1.34,1.34,0,0,0,0,1.92l5,5a1.39,1.39,0,0,0,1.95,0l4.84-4.82a1.38,1.38,0,0,0-2-1.94l-3.86,3.86-4-4A1.42,1.42,0,0,0,7.1,7.87Z"></path>
                                                </svg>
                                            </a>
                                        </noindex>
                                    </li>
                                    <li className="footer__link_social footer__link_social_fb">
                                        <noindex>
                                            <a target="_blank" rel='nofollow' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}>
                                                <svg class="share-link__icon share-link__icon--$share_link_service" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" viewBox="0 0 10.38 20">
                                                    <path d="M10.38.14V3.32H8.5a1.77,1.77,0,0,0-1.4.43,2,2,0,0,0-.36,1.3V7.32h3.52L9.8,10.88H6.74V20H3.06V10.88H0V7.32H3.06V4.7A4.67,4.67,0,0,1,4.31,1.23,4.56,4.56,0,0,1,7.64,0,20.93,20.93,0,0,1,10.38.14Z" transform="translate(0 0)"></path>
                                                </svg>
                                            </a>
                                        </noindex>
                                    </li>
                                    <li className="footer__link_social footer__link_social_tw">
                                        <noindex>
                                            <a target="_blank" rel='nofollow' href={`https://twitter.com/intent/tweet?url=${window.location}&text=${article?.title}`}>
                                                <svg class="share-link__icon share-link__icon--$share_link_service" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" viewBox="0 0 24.63 20">
                                                    <path d="M24.62,2.38A10.25,10.25,0,0,1,22.09,5c0,.15,0,.37,0,.66a14.61,14.61,0,0,1-.59,4.06,14.84,14.84,0,0,1-1.81,3.88,15.61,15.61,0,0,1-2.88,3.29,13,13,0,0,1-4,2.28A14.75,14.75,0,0,1,7.75,20,14,14,0,0,1,0,17.73a9,9,0,0,0,1.22.07,9.86,9.86,0,0,0,6.26-2.16,4.9,4.9,0,0,1-2.93-1,4.88,4.88,0,0,1-1.78-2.49,6.64,6.64,0,0,0,1,.08,5.45,5.45,0,0,0,1.33-.17,4.92,4.92,0,0,1-2.9-1.75A4.85,4.85,0,0,1,1,7.09V7a5,5,0,0,0,2.28.64A5.13,5.13,0,0,1,1.64,5.88,4.91,4.91,0,0,1,1,3.47,4.93,4.93,0,0,1,1.72.92a14.27,14.27,0,0,0,4.6,3.73,14,14,0,0,0,5.8,1.55A5.76,5.76,0,0,1,12,5.05a4.83,4.83,0,0,1,1.48-3.57A4.83,4.83,0,0,1,17.05,0a4.85,4.85,0,0,1,3.68,1.59A9.76,9.76,0,0,0,23.94.38a5,5,0,0,1-2.22,2.78A10.34,10.34,0,0,0,24.62,2.38Z" transform="translate(0 0)"></path>
                                                </svg>
                                            </a>
                                        </noindex>
                                    </li>
                                    <li className="footer__link_social footer__link_social_li">
                                        <noindex>
                                            <a target="_blank" rel='nofollow' href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location}`}>
                                            <svg class="share-link__icon share-link__icon--$share_link_service" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M20.45,20.45H16.89V14.88c0-1.33,0-3-1.85-3s-2.13,1.45-2.13,2.94v5.67H9.35V9h3.42v1.56h0a3.76,3.76,0,0,1,3.37-1.85c3.61,0,4.27,2.37,4.27,5.46ZM5.34,7.43h0A2.07,2.07,0,1,1,7.4,5.37h0A2.07,2.07,0,0,1,5.34,7.43m1.78,13H3.56V9H7.12ZM22.22,0H1.77A1.76,1.76,0,0,0,0,1.73V22.27A1.76,1.76,0,0,0,1.77,24H22.22A1.76,1.76,0,0,0,24,22.27V1.73A1.77,1.77,0,0,0,22.22,0"></path>
                                            </svg>
                                            </a>
                                        </noindex>
                                    </li>
                                    <li className="footer__link_social footer__link_social_re">
                                        <noindex>
                                            <a target="_blank" rel='nofollow' href={`https://reddit.com/submit?url=${window.location}`}>
                                            <svg class="share-link__icon share-link__icon--$share_link_service" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.97 21.3">
                                                <path d="M24,10.81a3,3,0,0,1-.09.73,2.73,2.73,0,0,1-.29.68,2.55,2.55,0,0,1-1.08,1,1.41,1.41,0,0,1,0,.2,2.67,2.67,0,0,1,0,.4,1.41,1.41,0,0,1,0,.2c0,4-4.69,7.3-10.49,7.3S1.56,18,1.56,14a1.41,1.41,0,0,1,0-.2,2.67,2.67,0,0,1,0-.4,1.41,1.41,0,0,1,0-.2A2.59,2.59,0,0,1,.13,11.53,2.63,2.63,0,0,1,4.46,8.9,12.24,12.24,0,0,1,6.07,8,13.55,13.55,0,0,1,7.8,7.28a14.15,14.15,0,0,1,1.82-.43,12.71,12.71,0,0,1,1.85-.16L12.81.44a.5.5,0,0,1,.08-.2.57.57,0,0,1,.16-.15.5.5,0,0,1,.2-.08h.22l4.41.88A1.81,1.81,0,1,1,17.65,2L13.8,1.18,12.63,6.8A12.58,12.58,0,0,1,14.46,7,12.77,12.77,0,0,1,18,8.09,13.15,13.15,0,0,1,19.55,9a2.61,2.61,0,0,1,1.33-.68,2.58,2.58,0,0,1,1.48.14,2.66,2.66,0,0,1,1.17.93A2.61,2.61,0,0,1,24,10.81ZM6.14,13.3a1.65,1.65,0,0,0,.39.58,1.8,1.8,0,0,0,1.27.53A1.81,1.81,0,1,0,6.14,13.3Zm10.31,4.32a.48.48,0,0,0,.14-.34.45.45,0,0,0-.13-.35.5.5,0,0,0-.35-.14.52.52,0,0,0-.35.12,5,5,0,0,1-.86.51,5.26,5.26,0,0,1-.93.36,6.55,6.55,0,0,1-1,.2,5.46,5.46,0,0,1-1,0,6.64,6.64,0,0,1-1,0,5.42,5.42,0,0,1-1-.21,5.3,5.3,0,0,1-.93-.37,6.17,6.17,0,0,1-.85-.51.44.44,0,0,0-.33-.11.45.45,0,0,0-.32.14.52.52,0,0,0-.14.32.44.44,0,0,0,.11.33,7,7,0,0,0,1,.63,7.47,7.47,0,0,0,1.1.45,7,7,0,0,0,2.34.3,6.86,6.86,0,0,0,3.44-.75,6.57,6.57,0,0,0,1-.63Zm-.33-3.07a1.75,1.75,0,0,0,.71-.13,2.06,2.06,0,0,0,.6-.41,1.87,1.87,0,0,0,.39-.61,2.18,2.18,0,0,0,.12-.72,1.8,1.8,0,1,0-3.47.69,1.81,1.81,0,0,0,1.67,1.11Z" transform="translate(-0.03)"></path>
                                            </svg>
                                            </a>
                                        </noindex>
                                    </li>




                                    <li
                                        className="footer__link_social article__socials_copy"
                                        onClick={(e) => {
                                            copyLink(e);
                                        }}
                                    >
                                        <input value={location} />
                                        <button>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.4649 11.293C9.5979 10.16 11.5739 10.16 12.7069 11.293L13.4139 12L14.8279 10.586L14.1209 9.879C13.1779 8.935 11.9219 8.414 10.5859 8.414C9.2499 8.414 7.9939 8.935 7.0509 9.879L4.9289 12C3.9932 12.9387 3.46777 14.2101 3.46777 15.5355C3.46777 16.8609 3.9932 18.1323 4.9289 19.071C5.39277 19.5355 5.94387 19.9038 6.55052 20.1547C7.15717 20.4056 7.80742 20.5342 8.4639 20.533C9.12056 20.5344 9.77102 20.4059 10.3779 20.155C10.9847 19.9041 11.5359 19.5357 11.9999 19.071L12.7069 18.364L11.2929 16.95L10.5859 17.657C10.0224 18.218 9.25957 18.533 8.4644 18.533C7.66923 18.533 6.90643 18.218 6.3429 17.657C5.7814 17.0937 5.46611 16.3308 5.46611 15.5355C5.46611 14.7402 5.7814 13.9773 6.3429 13.414L8.4649 11.293Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M12.0001 4.929L11.2931 5.636L12.7071 7.05L13.4141 6.343C13.9776 5.78199 14.7404 5.46703 15.5356 5.46703C16.3308 5.46703 17.0936 5.78199 17.6571 6.343C18.2186 6.90627 18.5339 7.66917 18.5339 8.4645C18.5339 9.25983 18.2186 10.0227 17.6571 10.586L15.5351 12.707C14.4021 13.84 12.4261 13.84 11.2931 12.707L10.5861 12L9.17212 13.414L9.87912 14.121C10.8221 15.065 12.0781 15.586 13.4141 15.586C14.7501 15.586 16.0061 15.065 16.9491 14.121L19.0711 12C20.0068 11.0613 20.5322 9.78992 20.5322 8.4645C20.5322 7.13908 20.0068 5.86772 19.0711 4.929C18.1327 3.99281 16.8612 3.46705 15.5356 3.46705C14.21 3.46705 12.9386 3.99281 12.0001 4.929Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>
                                    </li>

                                    <div className="article__reactions">
                                        <div className={activeStickers.includes(1) ? "article__reactions__item active" : "article__reactions__item"} onClick={() => leaveSticker(1)}>
                                            <div className="article__reactions__item-emoji">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000" width="30px" height="30px" viewBox="0 0 1024 1024" class="icon">
                                                    <path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"/>
                                                </svg>
                                            </div>
                                            <p className="article__reactions__item-count">{stickersCount['1'] || 0}</p>
                                        </div>
                                        <div className={activeStickers.includes(2) ? "article__reactions__item active" : "article__reactions__item"} onClick={() => leaveSticker(2)}>
                                            <div className="article__reactions__item-emoji">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="30px" height="30px" viewBox="0 0 1024 1024" class="icon">
                                                    <path d="M885.9 490.3c3.6-12 5.4-24.4 5.4-37 0-28.3-9.3-55.5-26.1-77.7 3.6-12 5.4-24.4 5.4-37 0-28.3-9.3-55.5-26.1-77.7 3.6-12 5.4-24.4 5.4-37 0-51.6-30.7-98.1-78.3-118.4a66.1 66.1 0 0 0-26.5-5.4H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h129.3l85.8 310.8C372.9 889 418.9 924 470.9 924c29.7 0 57.4-11.8 77.9-33.4 20.5-21.5 31-49.7 29.5-79.4l-6-122.9h239.9c12.1 0 23.9-3.2 34.3-9.3 40.4-23.5 65.5-66.1 65.5-111 0-28.3-9.3-55.5-26.1-77.7zM184 456V172h81v284h-81zm627.2 160.4H496.8l9.6 198.4c.6 11.9-4.7 23.1-14.6 30.5-6.1 4.5-13.6 6.8-21.1 6.7a44.28 44.28 0 0 1-42.2-32.3L329 459.2V172h415.4a56.85 56.85 0 0 1 33.6 51.8c0 9.7-2.3 18.9-6.9 27.3l-13.9 25.4 21.9 19a56.76 56.76 0 0 1 19.6 43c0 9.7-2.3 18.9-6.9 27.3l-13.9 25.4 21.9 19a56.76 56.76 0 0 1 19.6 43c0 9.7-2.3 18.9-6.9 27.3l-14 25.5 21.9 19a56.76 56.76 0 0 1 19.6 43c0 19.1-11 37.5-28.8 48.4z"/>
                                                </svg>
                                            </div>
                                            <p className="article__reactions__item-count">{stickersCount['2'] || 0}</p>
                                        </div>
                                    </div>
                                </ul>
                            </div>        
                            
                            <div className="article__new-comment">
                                <h5>Kommentare</h5>
                                {isLoggedIn ? (
                                    <form
                                        action=""
                                        className="article__new-comment__form"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            leaveComment(e);
                                        }}
                                    >
                                        <textarea name="text" id="comment" placeholder="Leave comment" className="article__new-comment__form__text" onKeyDown={(e) => {submitCommentForm(e);}}></textarea>
                                        <input type="submit" value="Send" className="article__new-comment__form__button" />
                                    </form>
                                ) : (
                                    <div className="article__new-comment__login">
                                        { formActive && <Modal open={formActive} setOpen={setFormActive}></Modal> }
                                        <p>
                                            Um Kommentare zu hinterlassen,{" "}
                                            <button
                                                className="article__new-comment__login__button"
                                                onClick={() => {
                                                    setFormActive(true);
                                                }}
                                            >
                                                melden Sie sich an
                                            </button>
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="article__comments">
                                {comments.map((comment) => {
                                    return !comment.CommentId && <Comment c={comment} commentId={comment.id || new Date().getTime()} setReplyId={setReplyId} replyId={replyId} ArticleId={params.id} user={user} setLoginFormActive={setFormActive} />
                                })}
                            </div>
                        </div>
                        <Timeline></Timeline>
                    </div>
                </div>
            ) : (
                <Preloader></Preloader>
            )}
        </div>
    ) : <NotFound></NotFound>;
};