import { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import "moment/locale/ru";
import videoExtensions from "video-extensions";
import { useLocation } from 'react-router-dom'

import "./card.scss";

export const Card = ({ type, article, titlePosition = "regular" }) => {
    const { pathname } = useLocation();
    let title;
    switch (type) {
        case "img-down": {
            title = <h5 className="card__title">{article?.title}</h5>;
            break;
        }
        case "author": {
            title = <h5 className="card__title">{article?.title}</h5>;
            break;
        }
        case "title-right": {
            title = <h6 className="card__title">{article?.title}</h6>;
            break;
        }
        case "title-bottom": {
            title = <p className="card__title">{article?.title}</p>;
            break;
        }
        case "medium": {
            title = <h5 className="card__title">{article?.title}</h5>;
            break;
        }
        default: {
            title = <h4 className="card__title">{article?.title}</h4>;
        }
    }

    let date = new Date(article?.publishDate);
    moment.locale("ru");
    let createdAt = moment(date).format("L") + " " + moment(date).format("LT");

    let photo;
    if (article?.data) {
        JSON.parse(article.data).blocks?.forEach((block) => {
            if ((block.type = "image" && !photo)) {
                photo = block.data?.file?.url;
            }
        });
        if (!photo) {
            JSON.parse(article.data).blocks?.forEach((block) => {
                if ((block.type = "carousel" && !photo)) {
                    photo = block.data[0]?.url;
                }
            });
        }
    }
    // photo = "https://heise.cloudimg.io/width/1050/q75.png-lossy-75.webp-lossy-75.foil1/_www-heise-de_/imgs/18/4/1/9/2/4/0/5/master_schwarzesmeer-673692ff3c6ebb61.jpeg"
    const webpImg = photo?.split(".")[0] + ".webp";

    
    if (article && article.Article_authors) {
        article.author = article.Article_authors[0];
    }

    function createMarkup(str) {
        return { __html: str };
    }
    if (article && article.text) {
        let div = document.createElement("div");
        div.innerHTML = article.text;
        article.text = div.textContent;
        div.remove();
    }

    const [imgChecked, setImgChecked] = useState(false);

    return (
        <article
            className={classnames(`card ${type}`, {
                "title-top": titlePosition === "top",
            })}
        >
            {
                titlePosition === "top" &&
                <a href={`/read/${Math.round(new Date(date).getTime() / 1000)}/${article?.id}`} className="card__title_and_text card__title_top">
                    {title}
                </a>
            }
            <a href={`/read/${Math.round(new Date(date).getTime() / 1000)}/${article?.id}`} className="card__photo">
                {videoExtensions.includes(photo?.split(".").pop()) ? (
                    <video>
                        <source src={photo + "#t=0.001"} alt={article?.title} poster />
                    </video>
                ) : (
                    <picture>
                        <source srcSet={webpImg} alt={article?.title} type="image/webp" />
                        <img
                            alt={article?.title}
                            src={photo?.split('.')[1] === 'png' ? photo?.split('.')[0] + '.jpg' : photo}
                            onError={(e) => {
                                if (!imgChecked) {
                                    setImgChecked(true);
                                    e.target.parentNode.children[0].srcset = photo?.split('.')[1] === 'png' ? photo?.split('.')[0] + '.jpg' : photo
                                }
                            }}
                        />
                    </picture>
                )}
            </a>
            <div className="card__text_content">
                <a href={`/read/${Math.round(new Date(date).getTime() / 1000)}/${article?.id}`} className="card__title_and_text">
                    {title}
                    <p className="card__text">{article?.text}</p>
                </a>
                <div className="card__tag_and_time">
                    <a href={`/category/${article?.Category?.name}`} className="card__tag">
                        {article?.Category?.name}
                    </a>
                    <div className="card__time">{createdAt}</div>
                    <div className="card__comments">
                        <div className="card__comments_icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="xMinYMin"
                                viewBox="0 0 20 20"
                                enableBackground="new 0 0 20 20"
                            >
                                {/* <polygon id="Kommentare" fill={article?.Comments > 0 ? "#fe1616" : "#ffa41e"} points="20,3 0,3 0,14 4,14 4,18 8,14 20,14 "/> */}
                                <polygon id="Kommentare" fill={"orange"} points="20,3 0,3 0,14 4,14 4,18 8,14 20,14 "/>
                            </svg>          
                        </div>
                        <div className="card__comments_number">{article?.Comments || 0}</div>
                    </div>
                    {/* <div className="card__comments">
                        <div className="card__comments_icon" style={{"margin-top": "-4px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -0.5 21 21" version="1.1">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Dribbble-Light-Preview" transform="translate(-259.000000, -760.000000)" fill="orange">
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path d="M203,620 L207.200006,620 L207.200006,608 L203,608 L203,620 Z M223.924431,611.355 L222.100579,617.89 C221.799228,619.131 220.638976,620 219.302324,620 L209.300009,620 L209.300009,608.021 L211.104962,601.825 C211.274012,600.775 212.223214,600 213.339366,600 C214.587817,600 215.600019,600.964 215.600019,602.153 L215.600019,608 L221.126177,608 C222.97313,608 224.340232,609.641 223.924431,611.355 L223.924431,611.355 Z" id="like-[#1385]"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </div>
                        <div className="card__comments_number">{article.stickers ? (article.stickers["1"] || 0) : 0}</div>
                    </div> */}
                </div>
                {article?.author && decodeURI(pathname).includes("Meinung") && (
                    <a href={`/author/${article?.author.id}`} className="card__author">
                        {article?.author?.name} {article?.author?.lastname}
                    </a>
                )}
            </div>
        </article>
    );
};