import { React, useState, useEffect } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import axios from 'axios';

import './search.scss';
import { Card } from '../Card'
import Timeline from '../Timeline'
import SearchForm from './form';
import Preloader from '../../Preloader';
import { Helmet } from "react-helmet";

export const Search = () => {
    const [request, setRequest] = useState({date: 'full', sortBy: 'date'});
    const [result, setResult] = useState({articles: [], count: 0});
    const [formInfo, setFormInfo] = useState({});
    const [articlesOffset, setArticlesOffset] = useState(2);
    const [articlesFetching, setArticlesFetching] = useState(false);
    const [emptyArticles, setemptyArticles] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [desc, setDesc] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        document.title = 'Search';
        if (params.content) {
            const content = params.content
            if (content === 404) {
                setNotFound(true);
            } else if (content === "All") {
                setRequest({...request, text: ""});
            } else {
                setRequest({...request, category: content})
            }
        } else if (searchParams.has('text')) {
            setRequest({...request, text: searchParams.get('text')})
            setFormInfo({...formInfo, text: searchParams.get('text')})
        } else if (params.author) {
            setRequest({...request, id: params.author})
            setFormInfo({...formInfo, author: params.author})
        } else {
            setNotFound(true);
        }
    }, []);

    useEffect(() => {
        let url;
        if (request.category) {
            url = 'tag'
            document.title = `Neuigkeiten in den Kategorien "${request.category}" - Berlin 24/7`
            setDesc(`Entdecken Sie rund um die Uhr die neuesten Nachrichten in der Kategorie "${request.category}" auf Berlin 24/7`)
            setFormInfo({...formInfo, category: request.category})
        } else if (request.text || request.text === '') {
            if (request.text[0] === '#') {
                window.location.href = `/tag/`
            }
            url = 'getArticleByText';
            document.title = `Nachrichtensuche - Berlin 24/7`
            setDesc(`Aktuelle Neuigkeiten finden Sie auf der Website Berlin 24/7`)
        } else if (request.id) {
            url = 'getArticleByAuthor';
        }
        if (url) {
            axios.post(`/${url}`, {...request, offset: 1, limit: 10})
            .then(function (response) {
                if (response.data.author) {
                    setFormInfo({...formInfo, author: response.data.author})
                }
                setResult(response.data)
                setFetched(true);
            })
        }
    }, [request])

    useEffect(() => {
        if (articlesFetching) {
            let url;
            if (request.category) {
                url = 'tag'
            } else if (request.text || request.text === '') {
                url = 'getArticleByText';
            }
            else if (request.id) {
                url = 'getArticleByAuthor';
            }
            axios.post(`/${url}`, {
                ...request,
                offset: articlesOffset,
                limit: 10
            })
            .then(response => {
                if (response.data.articles.length) {
                    setResult({articles: [...result.articles, ...response.data.articles], count: response.data.count});
                    setArticlesOffset(prevState => prevState + 1)
                } else {
                    setemptyArticles(true);
                }
            })
            .finally(() => setArticlesFetching(false))
        }
    }, [articlesFetching])

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    const scrollHandler = (e) => {
        const footerHeight = document.querySelector('footer').scrollHeight;
        if ((e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) - footerHeight) < 300 && !emptyArticles) {
            setArticlesFetching(true);
        }
    }

    return !notFound ? (
        <div className="search">
            <Helmet>
                <meta name="description" content={desc} />
            </Helmet>
            <div className="container">
                <ul className="article__breadcumbs">
                    <li><a href="/">Berlin 24/7</a></li>
                    {/* <li><a href={`/category/${article.Category?.name}`}>{article.Category?.name}</a></li> */}
                    <li><div>{params.content || searchParams.get('text') || 'Alle Nachrichten'}</div></li>
                </ul>
                <div className="search__content">
                    <main>
                        <SearchForm search={setRequest} info={formInfo} count={result.count}></SearchForm>
                        {fetched ?
                         Boolean(result.articles.length) ?
                                <div className="search__result">
                                    { result.articles.map((article, i) =>
                                        <Card key={i} article={article} type='medium'></Card>
                                    )}
                                </div>
                            :
                                <div className="search__result">
                                    <div className="search__zero">
                                        <div className="search__zero_text">
                                            Für Ihre Suche wurden keine Ergebnisse gefunden. Bitte versuchen Sie es mit anderen Optionen.
                                        </div>
                                    </div>
                                </div>
                        :
                        <Preloader></Preloader>
                        }
                    </main>
                    <Timeline></Timeline>
                </div>
            </div>

        </div>
    ) : null
}