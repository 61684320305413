import React, { useState, useEffect } from "react";
import axios from "axios";

import "./index.scss";
import { Card } from "../Card";
import Preloader from "../../Preloader";

const Timeline = ({ page = 1, setArticlesFetching, type = "aside" }) => {
    const [news, setNews] = useState([]);
    const [offset, setOffset] = useState(1);

    useEffect(() => {
        axios.post("/timeline", { offset: 1, limit: 20 }).then(function (response) {
            setNews([...news, ...response.data]);
        });
    }, []);

    const handleScroll = (e) => {
        const height = e.target.scrollHeight;
        const scrolled = e.target.scrollTop + e.target.offsetHeight;
        const threshold = height - scrolled;
        if (threshold < 600) {
            axios.post("/timeline", { offset: offset + 1, limit: 20 }).then(function (response) {
                setNews([...news, ...response.data]);
                setOffset(offset + 1);
            });
        }
    };

    useEffect(() => {
        if (type === "timeline") {
            axios.post("/timeline", { offset: page, limit: 20 }).then(function (response) {
                setNews([...news, ...response.data]);
                setArticlesFetching(false);
            });
        }
    }, [page]);

    return (
        <aside className="timeline">
            <div className="timeline__wrapper">
                <div className="timeline__head">
                    <div className="timeline__head_text">News</div>
                </div>
                {Boolean(news.length) ? (
                    <div className="timeline__news" onScroll={(e) => handleScroll(e)}>
                        {news?.map((article, i) => (
                            <Card key={i} article={article} type="only-title"></Card>
                        ))}
                    </div>
                ) : (
                    <Preloader></Preloader>
                )}
            </div>
        </aside>
    );
};

export default Timeline;
