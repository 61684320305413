import React, {useState, useEffect, useRef, useDebugValue} from 'react';
import '../header.scss';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import classnames from 'classnames';
import { DropdownSelect } from '../../DropdownSelect'

const Modal = ({open, setOpen, setAuth, exit}) => {
    const [type, setType] = useState('login');
    const [loginError, setLoginError] = useState('');
    const [recoveryKey, setRecoveryKey] = useState('');
    const [feedbackType, setFeedbackType] = useState(0);
    const [file, setFile] = useState('');
    const [validate, setValidate] = useState({
        email: true,
        password: true,
        passwordsMatch: true,
        notEmpty: true
    })
    const [params, setParams] = useSearchParams();
    const [agreeValidate, setAgreeValidate] = useState(true);

    useEffect(() => {
        if (exit === 'exit') {
            changeType('exit');
        }
        if (exit === 'news') {
            changeType('news')
        }
        if (exit === 'sub') {
            changeType('sub')
        }
    }, [open])


    useEffect(() => {
        if (params.has('recovery')) {
            changeType('changePsw')
            setOpen(true)
            setRecoveryKey(params.get('recovery'))
        }
    }, [])

    const navigate = useNavigate();

    const fileInputRef = useRef();

    const types = {
        login: {
            title: 'Anmeldung',
            submit: 'Anmeldung',
            change: {
                text: 'Registrieren',
                type: 'signup'
            }
        },
        signup: {
            title: 'Registrieren',
            submit: 'Registrieren',
            change: {
                text: 'Anmeldung',
                type: 'login'
            }
        },
        forgot: {
            title: 'Passwort vergessen',
            submit: 'Passwort wiederherstellen',
            change: {
                text: 'Anmeldung',
                type: 'login'
            }
        },
        confirm: {
            title: 'Bestätigung',
            submit: 'Bereit',
            change: {
                text: '',
                type: ''
            }
        },
        reset: {
            title: 'Passwort vergessen',
            change: {
                text: '',
                type: ''
            }
        },
        changePsw: {
            title: 'Neues Passwort',
            submit: 'Kennwort ändern',
            change: {
                text: 'Anmeldung',
                type: 'login'
            }
        },
        success: {
            title: 'Neues Passwort',
            submit: 'Anmeldung',
            change: {
                text: '',
                type: ''
            }
        },
        exit: {
            title: 'Austragen',
            submit: 'Austragen',
            change: {
                text: '',
                type: ''
            }
        },
        news: {
            title: 'Kontakt',
            submit: 'Schicken',
            change: {
                text: '',
                type: ''
            }
        },
        sended: {
            title: '',
            submit: '',
            change: {
                text: '',
                type: ''
            }
        },
        sub: {
            title: 'Abonnement',
            submit: 'Jetzt abonnieren',
            change: {
                text: '',
                type: ''
            }
        },
        sub_success: {
            title: 'Danke fürs Abonnieren',
            submit: '',
            change: {
                text: '',
                type: ''
            }
        }
    }

    const changeType = (type) => {
        setType(type);
        setValidate({
            email: true,
            password: true,
            passwordsMatch: true,
            notEmpty: true
        })
        setLoginError('')
    }

    let text = types[type];

    const handleSubmit = (event) => {
        setLoginError('')
        if (type === 'confirm') {
            changeType('login')
            return
        }
        if (type === 'success') {
            changeType('login')
            return
        }
        if (type === 'exit') {
            function setCookie(name, value, options = {}) {
                options = {
                    path: '/',
                    ...options,
                };

                if (options.expires instanceof Date) {
                    options.expires = options.expires.toUTCString();
                }

                let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

                for (let optionKey in options) {
                    updatedCookie += '; ' + optionKey;
                    let optionValue = options[optionKey];
                    if (optionValue !== true) {
                        updatedCookie += '=' + optionValue;
                    }
                }

                document.cookie = updatedCookie;
            }

            function deleteCookie(name) {
                setCookie(name, '', {
                    'max-age': -1,
                });
            }

            deleteCookie('authorization');
            navigate('/')
            window.location.reload()
            return
        }
        let data = Object.fromEntries(new FormData(event.target).entries());
        let fd = new FormData(event.target)

        let notEmpty = true;
        Object.keys(data).map(key => {
            if (!data[key].length && key !== 'file') {
                notEmpty = false;
            }
        });

        let re = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
        let myMail = data.email;
        let mailValidate = true;
        if (myMail) {
            mailValidate = re.test(myMail);
        }

        let passwordValidate = true;
        let passwordMatch = true;
        if (type === 'signup') {
            let passwordFirst = data.password
            let passwordSecond = data.password_2;
            if (passwordFirst.length < 6) {
                passwordValidate = false;
            } else if (passwordFirst !== passwordSecond) {
                passwordMatch = false;
            }
        }
        setValidate({...validate, email: mailValidate, notEmpty: notEmpty, password: passwordValidate, passwordsMatch: passwordMatch});
        if (mailValidate && notEmpty && passwordValidate && passwordMatch) {
            let url;
            switch (type) {
                case 'login': {
                    url = "/login";
                    break;
                }
                case 'signup': {
                    url = "/register";
                    break;
                }
                case 'forgot': {
                    url = '/recoveryPass';
                    break;
                }
                case 'changePsw':  {
                    url = '/newPassword';
                    data = {...data, token: recoveryKey};
                    break;
                }
                case 'news': {
                    url = '/sendNews';
                    break;
                }
                case 'sub': {
                    url = '/subscribeEmail';
                    break;
                }
            }
            axios.post(url, fd)
              .then(function (response) {
                // if (type === 'signup') {
                //     changeType('confirm');
                // }
                if (type === 'news') {
                    changeType('sended')
                }
                else if (type === 'forgot') {
                    changeType('reset')
                } else if (type === 'changePsw') {
                    navigate("/");
                    window.location.reload();
                } else if (type === 'sub') {
                    changeType('sub_success')
                } else {
                    setOpen(false);
                    navigate("/");
                    setAuth(true);
                }
              })
              .catch(function (error) {
                setLoginError(error.response.data)
              });
        } else {
        }
    }

    const handleTypeChange = (name, option) => {
        setFeedbackType(option)
    }

    // function profilePhotoChange(e) {
    //     setProfilePhoto(URL.createObjectURL(e.target.files[0]));
    // }

    // const photoInput = useRef();

    // function uploadProfilePhoto() {
    //     const btn = photoInput.current;
    //     btn.click();
    // }

    return (
        <div className={open ? 'header__login_form header__login_form_active' : 'header__login_form'} onClick={() => setOpen(false)}>
            <div className='header__login_form_content' onClick={(e) => e.stopPropagation()}>
                <div className='header__login_form_close' onClick={() => setOpen(false)}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.9866 10.5734L19.9149 17.6434L12.8449 10.5734L10.4883 12.93L17.5583 20L10.4883 27.07L12.8449 29.4267L19.9149 22.3567L26.9866 29.4267L29.3433 27.07L22.2733 20L29.3433 12.93L26.9866 10.5734Z" fill="#B9B9B9"/>
                    </svg>
                </div>
                <h2 className={classnames("header__login_form_title", {"exit": type === 'exit'})}>{text.title}</h2>
                { !validate.notEmpty && <div className="header__login_form_error">Felder dürfen nicht leer sein</div>}
                <div className="header__login_form_error">{loginError}</div>
                { !validate.email && <div className="header__login_form_error">Überprüfen Sie die Richtigkeit der eingegebenen Daten</div>}
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e)}} className='header__login_form_form'>
                    {type === 'login' &&
                        <fieldset>
                            <input className={validate.email ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='email' type='text' placeholder='E-mail'/>
                            <input className='header__login_form_input' name='password' type='password' placeholder='Passwort'/>
                            <p className="header__login_form_forgot" onClick={() => changeType('forgot')}>Passwort vergessen?</p>
                        </fieldset>
                    }
                    {type === 'signup' &&
                        <fieldset>
                            <input className={validate.email ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='email' type='text' placeholder='E-mail *'/>
                            <input className='header__login_form_input' name='name' type='text' placeholder='Name'/>
                            <input className='header__login_form_input' name='lastname' type='text' placeholder='Nachname'/>
                            <input className={validate.password && validate.passwordsMatch ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='password' type='password' placeholder='Passwort *'/>
                            { !validate.password && <div className="header__login_form_error header__login_form_error_psw">Das Passwort muss mindestens 6 Zeichen enthalten</div>}
                            { !validate.passwordsMatch && <div className="header__login_form_error header__login_form_error_psw">Die Passwörter stimmen nicht überein</div>}
                            <input className={validate.password && validate.passwordsMatch ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='password_2' type='password' placeholder='Nochmals das Passwort *'/>
                        </fieldset>
                    }
                    {type === 'changePsw' &&
                        <fieldset>
                            <input className={validate.password && validate.passwordsMatch ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='password' type='password' placeholder='Passwort *'/>
                            { !validate.password && <div className="header__login_form_error header__login_form_error_psw">Das Passwort muss mindestens 6 Zeichen enthalten</div>}
                            { !validate.passwordsMatch && <div className="header__login_form_error header__login_form_error_psw">Die Passwörter stimmen nicht überein</div>}
                            <input className={validate.password && validate.passwordsMatch ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='password_2' type='password' placeholder='Nochmals das Passwort *'/>
                        </fieldset>
                    }
                    {type === 'forgot' &&
                        <fieldset>
                            <p className="header__login_form_forgot_text">Ein Link zum Zurücksetzen Ihres Passworts wird an Ihre E-Mail-Adresse gesendet.</p>
                            <input className={validate.email ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='email' type='text' placeholder='E-mail'/>
                        </fieldset>
                    }
                    {type === 'confirm' &&
                        <p className="header__login_form_confirm_text">An die angegebene E-Mail-Adresse wurde eine E-Mail mit einem Bestätigungslink gesendet.</p>
                    }
                    {type === 'success' &&
                        <p className="header__login_form_confirm_text">das Passwort wurde erfolgreich geändert</p>
                    }
                    {type === 'reset' &&
                        <p className="header__login_form_confirm_text">An die angegebene E-Mail-Adresse wurde eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet.</p>
                    }
                    {type === 'news' && 
                        <fieldset>
                            <input name='type' type="hidden" value={feedbackType}/>
                            <DropdownSelect news={true} submit={handleTypeChange} name={'typeSelect'} options={[{text: 'Neuigkeiten vorschlagen', value: '0'}, {text: 'Eine Beschwerde einreichen', value: '1'}]}></DropdownSelect>
                            <input type="text" name="email" placeholder='E-mail' className={validate.email ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} />
                            <input type="text" name="title" placeholder='Thema' className='header__login_form_input' />
                            <textarea name="text" placeholder='Text' className='header__login_form_input header__login_form_textarea'></textarea>
                            {/* <input type="file" name="photo" id="photo" onChange={profilePhotoChange} ref={photoInput} className="input__hidden" /> */}
                            <input ref={fileInputRef} type="file" name="file" onChange={(e) => setFile(e.target.files[0].name)} />
                            <div className="fake_input"
                            onClick={() => {
                                fileInputRef.current.click();
                            }}
                            >
                                <div className="text">
                                {file || 'Datei anhängen'}
                                </div>
                            </div>
                            {/* <div className="profile__info__right__profile-picture__photo" onClick={(e) => uploadProfilePhoto()}>
                                <img src={profilePhoto} alt="profile picture" />
                            </div> */}
                        </fieldset>
                    }
                    {type === 'sended' && 
                        <h2 className="header__login_form_title header__sended_text">Nachricht erfolgreich gesendet</h2>
                    }
                    {type === 'sub' &&
                        <fieldset>
                            <p className="header__login_form_forgot_text">Geben Sie Ihre E-Mail-Adresse ein, um Neuigkeiten zu erhalten.</p>
                            <input className={validate.email ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} name='email' type='text' placeholder='E-mail'/>
                        </fieldset>
                    }
                    {text.submit &&
                        <input type='submit' className='header__login_form_input header__login_form_submit' value={text.submit}/>
                    }
                </form>
                <p className='header__login_form_change_type_btn' onClick={() => changeType(text.change.type)}>{text.change.text}</p>
            </div>
        </div>
    )
}

export default Modal