import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/ru";
import axios from "axios";

import Modal from "../Header/ui/modal";
import "./profile.scss";

export const Profile = () => {
    const [profileData, setProfileData] = useState({
        name: "",
        lastname: "",
        email: "",
    });
    const [exitModalOpen, setExitModalOpen] = useState(false);
    const [exit, setExit] = useState("exit");
    const [profilePhoto, setProfilePhoto] = useState();
    const [validate, setValidate] = useState({
        email: true,
        password: true,
        passwordsMatch: true,
        notEmpty: true,
    });
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        axios.post("/getMe", {}).then((data) => {
            setProfileData(data.data);
            setProfilePhoto(`/uploads/${data.data.photo || "default_profile.png"}`);
        });
        axios.post("/getNotify", {}).then((data) => {
            setNotifications(data.data)
            data.data.forEach(n => {
                if (!n.isRead) {
                    axios.post("/readNotify", {id: n.id})
                }
            })
        });
    }, []);

    function profilePhotoChange(e) {
        setProfilePhoto(URL.createObjectURL(e.target.files[0]));
    }

    const photoInput = useRef();

    function uploadProfilePhoto() {
        const btn = photoInput.current;
        btn.click();
    }

    const handleSubmit = (event) => {
        let data = Object.fromEntries(new FormData(event.target).entries());

        let notEmpty = true;
        Object.keys(data).map((key) => {
            if (!data[key].length) {
                notEmpty = false;
            }
        });

        let re = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
        let myMail = data.email;
        let mailValidate = re.test(myMail);

        let passwordValidate = true;
        let passwordMatch = true;

        let passwordFirst = data.password;
        let passwordSecond = data.password_2;
        if ((passwordFirst.length < 6) & (passwordFirst.length != 0)) {
            passwordValidate = false;
        } else if (passwordFirst !== passwordSecond) {
            passwordMatch = false;
        }

        setValidate({
            ...validate,
            email: mailValidate,
            notEmpty: notEmpty,
            password: passwordValidate,
            passwordsMatch: passwordMatch,
        });

        if (mailValidate && passwordValidate && passwordMatch) {
            setProfileData(data);
            changeSettings(event);
        }
    };

    const changeSettings = async (event) => {
        try {
            const res = await axios({
                url: "/changeSettings",
                method: "post",
                data: new FormData(event.target),
                headers: { "Content-Type": "multipart/form-data" },
            });
        } catch (error) {}
    };

    function prepareDate(date) {
        moment.locale("ru");
        return moment(date).format("LT") + " " + moment(date).format("L");
    }

    const renderNotify = (comment) => {
        return (
            <>
                {
                    <a href={comment.link} key={comment.id} className={`notify ${comment.isRead && "readed"}`}>
                        <p className="notify__title">{comment.title}</p>
                        <p className="notify__action">{comment.action}</p>
                        <p className="notify__text">{comment.text}</p>
                    </a>
                }
            </>
        );
    };

    return (
        <div className="wrapper">
            <Modal open={exitModalOpen} setOpen={setExitModalOpen} exit={exit}></Modal>
            <div className="profile__container">
                <h1 className="profile__title">Anmelden</h1>
                <div className="profile__info">
                    <div className="profile__info__right">
                        <div className="profile__info__right__profile-picture">
                            <div className="profile__info__right__profile-picture__photo" onClick={(e) => uploadProfilePhoto()}>
                                <img src={profilePhoto} alt="profile picture" />
                            </div>
                            <h6 className="profile__info__left__text-title">
                                {profileData.name} {profileData?.lastname}
                            </h6>

                            {/* <button className="profile__info__right__profile-picture__btn-change">
                                Change
                            </button> */}
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e);
                            }}
                            className="profile__info__right__form"
                        >
                            <input type="file" name="photo" id="photo" accept="image/*" onChange={profilePhotoChange} ref={photoInput} className="input__hidden" />
                            <input maxLength={60} className={validate.email ? "profile__info__right__form__input" : "profile__info__right__form__input profile__info__right__form__input__error"} name="email" type="email" placeholder="E-mail *" defaultValue={profileData.email} />
                            <input maxLength={25} className="profile__info__right__form__input" name="name" type="text" placeholder="Name *" defaultValue={profileData.name} />
                            <input maxLength={25} className="profile__info__right__form__input" name="lastname" type="text" placeholder="Nachname" defaultValue={profileData?.lastname} />
                            <input className={validate.password && validate.passwordsMatch ? "profile__info__right__form__input" : "profile__info__right__form__input profile__info__right__form__input__error"} name="password" type="password" placeholder="Passwort *" />
                            {!validate.password && <div className="profile__login_form_error profile__login_form_error_psw">Das Passwort muss mindestens 6 Zeichen enthalten</div>}
                            {!validate.passwordsMatch && <div className="profile__login_form_error profile__login_form_error_psw">Die Passwörter stimmen nicht überein</div>}
                            <input className={validate.password && validate.passwordsMatch ? "profile__info__right__form__input" : "profile__info__right__form__input profile__info__right__form__input__error"} name="password_2" type="password" placeholder="Nochmals das Passwort *" />
                            <input type="submit" value="Speichern" className="profile__info__right__form__submit" />
                        </form>
                    </div>
                    <div>
                        <div className="profile__replyes">
                            <div className="article__comments">
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}
                                {notifications.map((comment) => {
                                    return !comment.CommentId && renderNotify(comment);
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
