import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {Helmet} from "react-helmet";
import classnames from 'classnames';
import { DropdownSelect } from '../DropdownSelect'

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./index.scss";

const Contacts = () => {
    const [contacts, setContacts] = useState();
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [theme, setTheme] = useState('');
    const [textArea, setTextArea] = useState('');
    const [feedbackType, setFeedbackType] = useState(0);
    const [file, setFile] = useState('');

    useEffect(() => {
        let getContacts = axios.post("/getInfo", {}).then((data) => {
            setContacts(data.data);
        });
    }, []);

    const handleTypeChange = (name, option) => {
        setFeedbackType(option)
    }

    const [loginError, setLoginError] = useState('');
    const [validate, setValidate] = useState({
        email: true,
        password: true,
        passwordsMatch: true,
        notEmpty: true
    })
    const [agreeValidate, setAgreeValidate] = useState(true);

    let text = {
        title: 'Kontakt',
        submit: 'Schicken',
        change: {
            text: '',
            type: ''
        }
    }

    const handleSubmit = (event) => {
        setLoginError('')
        let fd = new FormData(event.target)
        let data = Object.fromEntries(new FormData(event.target).entries());

        let notEmpty = true;
        Object.keys(data).map(key => {
            if (!data[key].length && key !== 'file') {
                notEmpty = false;
            }
        });

        let re = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
        let myMail = data.email;
        let mailValidate = true;
        if (myMail) {
            mailValidate = re.test(myMail);
        }

        let passwordValidate = true;
        let passwordMatch = true;
        setValidate({...validate, email: mailValidate, notEmpty: notEmpty, password: passwordValidate, passwordsMatch: passwordMatch});
        if (mailValidate && notEmpty && passwordValidate && passwordMatch) {
            let url = '/sendNews'
            // if (!data.agree) {
            //     setAgreeValidate(false);
            //     return
            // }
            axios.post(url, fd)
              .then(function (response) {
                setSuccess(true);
                setTextArea('');
                setEmail('');
                setTheme('');
                setFile('');
              })
              .catch(function (error) {
                setLoginError(error.response.data)
              });
        } else {
        }
    }

    const fileInputRef = useRef();

    return (
        <div className="wrapper">
            <Helmet>
                <title>Kontakt - Berlin 24/7</title>
                <meta name='description' content='Auf dieser Seite finden Sie die Kontaktdaten der Website von Berlin 24/7' />
            </Helmet>
            <div className="contacts__container">
                <h1 className="contacts__title">Kontakt</h1>
                <div className="contacts__items">
                    <div className="contacts__item">
                        <h6 className="contacts__subtitle">Adresse</h6>
                        <p className="contacts__text">{contacts?.address}</p>
                    </div>
                    <div className="contacts__item" id="contacts__phone">
                        <h6 className="contacts__subtitle">Telefonnummer</h6>
                        <a href="tel:+74957387323" className="contacts__link">
                            {contacts?.phone}
                        </a>
                    </div>
                    <div className="contacts__item" id="contacts__email">
                        <h6 className="contacts__subtitle">E-mail</h6>
                        <a href="mailto:info@company-name.ru" className="contacts__link">
                            {contacts?.email}
                        </a>
                    </div>
                </div>
                {/* <iframe className="contacts__map" src="https://yandex.ru/map-widget/v1/?ll=30.092322%2C59.940675&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1MzAwMDA5MxIr0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsyIKDW-G8kEVfcFvQg%2C%2C&z=9.75" width="100%" height="470" frameborder="0"></iframe> */}
                <h4 className="ad__title">Verbinde dich mit uns</h4>
                <div className="contacts__items">
                    <div className='header__login_form_content' style={{'padding':'0'}} onClick={(e) => e.stopPropagation()}>
                        {/* <h2 className={classnames("header__login_form_title")}>{text.title}</h2> */}
                        { !validate.notEmpty && <div className="header__login_form_error">Felder dürfen nicht leer sein</div>}
                        { success && <div className="header__login_form_error" style={{'color': '#222'}}>Nachricht erfolgreich gesendet</div>}
                        <div className="header__login_form_error">{loginError}</div>
                        { !validate.email && <div className="header__login_form_error">Überprüfen Sie die Richtigkeit der eingegebenen Daten</div>}
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e)}} className='header__login_form_form'>
                            <fieldset>
                                <input name='type' type="hidden" value={feedbackType}/>
                                <DropdownSelect news={true} submit={handleTypeChange} name={'typeSelect'} options={[{text: 'Neuigkeiten vorschlagen', value: '0'}, {text: 'Eine Beschwerde einreichen', value: '1'}]}></DropdownSelect>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="email" placeholder='E-mail' className={validate.email ? 'header__login_form_input' : 'header__login_form_input header__login_form_input_error'} />
                                <input value={theme} onChange={(e) => setTheme(e.target.value)} type="text" name="title" placeholder='Thema' className='header__login_form_input' />
                                <textarea value={textArea} onChange={(e) => setTextArea(e.target.value)} name="text" placeholder='Text' className='header__login_form_input header__login_form_textarea'></textarea>
                                <input type="file" name="file" ref={fileInputRef} onChange={(e) => setFile(e.target.files[0].name)} />
                                <div className="fake_input"
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                    >
                                        <div className="text">
                                        {file || 'Datei anhängen'}
                                        </div>
                                </div>
                            </fieldset>
                            <input type='submit' className='header__login_form_input header__login_form_submit' value={text.submit}/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
