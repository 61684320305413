import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "./modal";
import Search from "../../SearchForm/Search";
import { LoginBtn } from "./loginBtn";
import { ProfileBlock } from "./profileBlock";
import "./../header.scss";

import { useLocation } from 'react-router-dom'

export const Header = () => {
    const [formActive, setFormActive] = useState(false);
    const [formType, setFormType] = useState("login");
    const [isAuth, setAuth] = useState();
    const [user, setUser] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    const { pathname } = useLocation();
    const [notifications, setNotifications] = useState(2);
    const [contacts, setContacts] = useState({});
    const [authors, setAuthors] = useState([]);
    const [authorsSelectActive, setAuthorsSelectActive] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        window.addEventListener("click", (e) => closeActions(e));
        return () => window.removeEventListener("click", (e) => closeActions(e));
    }, [authorsSelectActive]);  
    
    const closeActions = (e) => {
        if (!e.target.closest(".header__tag.authors") && authorsSelectActive) {
            setAuthorsSelectActive(false);
        }
    }

    useEffect(() => {
        axios.post(`/getMe`, {}, { withCredentials: true }).then(function (response) {
            if (!response.data.id) {
                setAuth(false);
            } else {
                setAuth(true);
                setUser(response.data);
            }
        });
        axios.post("/getNotify", {isRead: false}).then((data) => {
            setNotifications(data.data.length);
        });
    }, [isAuth]);

    let profile;
    if (isAuth) {
        profile = <ProfileBlock setFormType={setFormType} setFormActive={setFormActive} user={user} setAuth={setAuth} notifications={notifications} />
    } else {
        profile = <LoginBtn formActive={formActive} setFormActive={setFormActive} />
    }

    const scrollRef = useRef();
    const prevBtnRef = useRef();
    const nextBtnRef = useRef();

    useEffect(() => {
        axios.post("/getCategories", {}).then((data) => {
            setCategories(data.data?.map(c => c.name));
        });
        axios.post("/getInfo", {}).then((data) => {
            setContacts(data.data);
        });
        const el = scrollRef.current;
        const prevBtn = prevBtnRef.current;
        const nextBtn = nextBtnRef.current;
        axios.post("/getAuthors", {}).then((data) => {
            setAuthors(data.data);
        });
        if (el) {
            const onWheel = (e) => {
                if (e.target.closest(".header__tag.authors")) return;
                if (e.deltaY === 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY * 4,
                    behavior: "smooth",
                });
            };
            const onScroll = (e) => {
                const scrollPos = el.scrollLeft;
                if (prevBtn && scrollPos <= 0) {
                    prevBtn.classList.add("disabled_btn");
                } else if (prevBtn && scrollPos > 0) {
                    prevBtn.classList.remove("disabled_btn");
                }
                if (nextBtn && el.scrollWidth - (scrollPos + el.offsetWidth) < 1) {
                    nextBtn.classList.add("disabled_btn");
                } else if (nextBtn && el.scrollWidth - (scrollPos + el.offsetWidth) > 1) {
                    nextBtn.classList.remove("disabled_btn");
                }
            };
            el.addEventListener("wheel", onWheel);
            el.addEventListener("scroll", onScroll);
            return () => {
                el.removeEventListener("wheel", onWheel);
                el.removeEventListener("scroll", onScroll);
            };
        }
    }, []);

    function scrollNav(dir) {
        const el = scrollRef.current;
        if (el) {
            const x = dir === "left" ? -el.offsetWidth : +el.offsetWidth;
            const scrollPos = el.scrollLeft + x / 1.5;
            el.scrollTo({
                left: scrollPos,
                behavior: "smooth",
            });
        }
    }

    return (
        <header>
            {formActive && <Modal open={formActive} setOpen={setFormActive} setAuth={setAuth} exit={formType}></Modal>}
            <div className="header__empty_space"></div>
            <div className={"header__wrapper"}>
                <div className="header__content">
                    <div className="header__logo_row">
                        <div className="header__logo">
                            <a href="/">
                                <img src="/logo.svg" alt="" />
                                {/* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" viewBox="0 0 180 39" role="img"><path d="M17.19.5v4.39h-5.87V38.5H6.13V4.89H0V.5Zm19.37 0v4.39h-8.81v11.94h7.87v4.38h-7.87v12.9h8.81v4.39h-14V.5Zm12.77 0v33.61h8.76v4.39h-14V.5Zm27.94 0v4.39h-8.81v11.94h7.87v4.38h-7.87v12.9h8.81v4.39h-14V.5Zm8.27 38V.5h6.19c4.44 0 10.11.4 10.11 10.18 0 7.71-3.1 11.29-11.15 11V38.5Zm5.15-33.71v12.6c4.44.3 5.68-2.07 5.68-6.3 0-3.43-1-6.3-5-6.3ZM127 19.5c0 6.85-.1 19.5-10.21 19.5s-10.21-12.65-10.21-19.5S106.69 0 116.8 0 127 12.65 127 19.5Zm-14.89 0c0 8.06.57 14.76 4.69 14.76s4.69-6.7 4.69-14.76-.57-14.76-4.69-14.76-4.69 6.7-4.69 14.76Zm26.6-19v33.61h8.76v4.39h-14V.5Zm18.46 38h-5.22V.5h5.22m20.92 5.84a7.21 7.21 0 0 0-4.4-1.61 4.61 4.61 0 0 0-4.6 4.89 6.05 6.05 0 0 0 1.45 4.13l3.93 4.18c3.52 3.48 5.53 6 5.53 11.14 0 5.34-3.41 9.93-9.15 9.93a9.21 9.21 0 0 1-5.69-1.81V32.1a8.57 8.57 0 0 0 5.12 2c3.21 0 4.55-2.47 4.55-5.29 0-7.56-10.91-9.07-10.91-19 0-5.34 2.9-9.72 8.74-9.72a10.71 10.71 0 0 1 5.48 1.26Z" fill="#b42900"/></svg> */}
                                {/* <span>NEWS</span> */}
                            </a>
                        </div>
                        <div className="header__media">
                            {
                                contacts.banner &&
                                <div className="header__media_wrapper">
                                    {
                                        JSON.parse(contacts.banner).mimetype.split('/')[0] === "video" ?
                                        <video src={`/uploads/${JSON.parse(contacts.banner).filename}`} className="header__media_item" autoplay="autoplay" muted="muted"></video>
                                        :
                                        <img src={`/uploads/${JSON.parse(contacts.banner).filename}`} className="header__media_item" />
                                    }
                                </div>
                            }
                        </div>
                        <div className="header__actions_wrapper">
                            <div onClick={() => setMenuOpen(!menuOpen)} className="header__link header__menu_btn">
                                <span className="header__menu_btn_icon"></span>
                                <span className="header__menu_btn_icon"></span>
                                <span className="header__menu_btn_icon"></span>
                            </div>
                            {profile}
                            <div className="header__actions">
                                <ul className="header__links">
                                {
                                        contacts.fb && <li>
                                        <a href={contacts.fb} className="header__link fb">
                                            <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin"><path fill="#fff" d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759H734V905H479V609h255V391q0-186 104-288.5T1115 0q147 0 228 12z"></path></svg>                                        
                                        </a>
                                    </li>
                                    }
                                    {
                                        contacts.tw && <li>
                                        <a href={contacts.tw} className="header__link tw">
                                            <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin"><path fill="#fff" d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5T1369.5 1125 1185 1335.5t-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5T285 1033q33 5 61 5 43 0 85-11-112-23-185.5-111.5T172 710v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5T884 653q-8-38-8-74 0-134 94.5-228.5T1199 256q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"></path></svg>
                                        </a>
                                    </li>
                                    }
                                    {
                                        contacts.tg && <li>
                                        <a href={contacts.tg} className="header__link tg">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 50 50" width="50px" height="50px"><path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445 c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758 c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125 c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077 C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"/></svg>
                                        </a>
                                    </li>
                                    }
                                    {
                                        contacts.email && <li>
                                        <a href={`mailto:${contacts.email}`} className="header__link m">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1998 1200">
                                            <path d="M0 0v1200h1998V0H0zm1819.39 100L996.11 762.72 178.61 100h1640.78zM100 1100V165l895.89 726.27L1898 165.1V1100H100z" fill="#fff"></path></svg>                                    </a>
                                    </li>
                                    }
                                </ul>
                                <Search />
                            </div>
                        </div>
                    </div>
                    <div className="header__tags_row">
                        <div
                            ref={prevBtnRef}
                            className="header__tag header__tag_nav_btn_prev disabled_btn"
                            onClick={function () {
                                scrollNav("left");
                            }}
                        >
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.70711ZM2 9H1V7H2V9Z" fill="black" />
                            </svg>
                        </div>
                        <nav ref={scrollRef} className={`${menuOpen ? "header__menu" : "header__menu_hidden"}`}>
                            <   div className="header__actions_wrapper mobile">
                                {profile}
                                <div className="header__actions">
                                    <ul className="header__links">
                                        {
                                            contacts.fb && <li>
                                            <a href={contacts.fb} className="header__link fb">
                                                <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin"><path fill="#fff" d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759H734V905H479V609h255V391q0-186 104-288.5T1115 0q147 0 228 12z"></path></svg>                                        
                                            </a>
                                        </li>
                                        }
                                        {
                                            contacts.tw && <li>
                                            <a href={contacts.tw} className="header__link tw">
                                                <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin"><path fill="#fff" d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5T1369.5 1125 1185 1335.5t-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5T285 1033q33 5 61 5 43 0 85-11-112-23-185.5-111.5T172 710v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5T884 653q-8-38-8-74 0-134 94.5-228.5T1199 256q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"></path></svg>
                                            </a>
                                        </li>
                                        }
                                        {
                                            contacts.tg && <li>
                                            <a href={contacts.tg} className="header__link tg">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 50 50" width="50px" height="50px"><path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445 c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758 c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125 c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077 C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"/></svg>
                                            </a>
                                        </li>
                                        }
                                        {
                                            contacts.email && <li>
                                            <a href={`mailto:${contacts.email}`} className="header__link m">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1998 1200">
                                                <path d="M0 0v1200h1998V0H0zm1819.39 100L996.11 762.72 178.61 100h1640.78zM100 1100V165l895.89 726.27L1898 165.1V1100H100z" fill="#fff"></path></svg>                                    </a>
                                        </li>
                                        }
                                    </ul>
                                    <Search />
                                    <div className="header__tags_sub mobile" onClick={() => {
                                        // setFormType("sub");
                                        // setFormActive(true);
                                    }}>
                                        <a className={`header__tag`} target="_blank" href="https://paypal.me/berlin247net?country.x=DE&locale.x=de_DE">unterstützen sie uns</a>
                                    </div>
                                </div>
                            </div>
                            <ul className="header__tags">
                                <a href={`/`} className={`header__tag`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 -0.5 21 21" version="1.1">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Dribbble-Light-Preview" transform="translate(-419.000000, -720.000000)" fill="#fff">
                                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                                    <path d="M379.79996,578 L376.649968,578 L376.649968,574 L370.349983,574 L370.349983,578 L367.19999,578 L367.19999,568.813 L373.489475,562.823 L379.79996,568.832 L379.79996,578 Z M381.899955,568.004 L381.899955,568 L381.899955,568 L373.502075,560 L363,569.992 L364.481546,571.406 L365.099995,570.813 L365.099995,580 L372.449978,580 L372.449978,576 L374.549973,576 L374.549973,580 L381.899955,580 L381.899955,579.997 L381.899955,570.832 L382.514204,571.416 L384.001,570.002 L381.899955,568.004 Z" id="home-[#1391]"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <div className="header__tags_scrolled" >
                                    {
                                        categories.map(c => {
                                            return <li key={c}>
                                                <a href={`/category/${c}`} className={`header__tag ${decodeURI(pathname).includes(c) && "active"}`}>
                                                    {c.toUpperCase()}
                                                </a>
                                            </li>
                                        })
                                    }
                                </div>
                                {Boolean(authors.length) && 
                                    <li>
                                        <div className={`header__tag authors ${(decodeURI(pathname).includes('author') || authorsSelectActive) && "active"}`} onClick={() => setAuthorsSelectActive(!authorsSelectActive)}>
                                            AUTOREN
                                            { authorsSelectActive &&
                                                <div className="header__authors_list">
                                                    {
                                                        authors.map(a => {
                                                            return <a href={`/author/${a.id}`} className={`header__author ${(decodeURI(pathname).includes(`/author/${a.id}`)) && "active"}`}>
                                                                        <div className="header__author_photo">
                                                                            <img src={`/uploads/${a.photo || "default_profile.png"}`} alt="" />
                                                                        </div> 
                                                                        <div className="header__author_name">{a.name} {a.lastname}</div>
                                                                    </a>
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </li>}
                                <li className="header__tags_sub" onClick={() => {
                                        // setFormType("sub");
                                        // setFormActive(true);
                                }}>
                                    <a className={`header__tag`} target="_blank" href="https://paypal.me/berlin247net?country.x=DE&locale.x=de_DE">unterstützen sie uns</a>
                                </li>
                            </ul>
                        </nav>
                        <div
                            ref={nextBtnRef}
                            className="header__tag header__tag_nav_btn_next"
                            onClick={function () {
                                scrollNav("right");
                            }}
                        >
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L6.58579 8L0.928932 13.6569C0.538408 14.0474 0.538408 14.6805 0.928932 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM7 9H8V7H7V9Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};