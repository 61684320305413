import React from "react";
import { ColorRing } from "react-loader-spinner";

import "./index.scss";

const Preloader = () => {
    return (
        <div className="preloader">
            <ColorRing visible={true} height="50" width="50" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" colors={["#6f869c", "#6f869c", "#6f869c", "#6f869c", "#6f869c"]} />
        </div>
    );
};

export default Preloader;
