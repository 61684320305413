import { React, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import './dropdownSelect.scss';
import moment from 'moment';

export const DropdownSelect = ({name, options, submit, news = false}) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState({text: options[0].text, value: options[0].value});
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [selectCount, setSelectCount] = useState(0);
    const [selectedDates, setSelectedDates] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            maxDate: new Date(),
            key: 'selection',
            color: "#fe1616",
            showDateDisplay: false
        }
    ]);

    window.addEventListener('click', function (e) {
        if (!e.target.closest(`.dropdown_select[data-name='${name}']`) && open) {
            setOpen(false)
            setCalendarOpen(false)
        }
    })

    const handleSelect = (d) => {
        if (selectCount === 1) {
            renderDates(d.selection)
            setSelectCount(0);
        } else {
            setSelectCount(prev => prev + 1)
        }
        setSelectedDates([d.selection])
    }

    const renderDates = (selection) => {
        const startDate = moment(selection.startDate).format('DD.M.YYYY')
        const endDate = moment(selection.endDate).format('DD.M.YYYY')
        const dates = [startDate, endDate].join('-');
        setValue({text: dates, value: dates})
        submit(name, dates);
        setCalendarOpen(false);
    }

    return (
        <div className={news ? 'dropdown_select news' : 'dropdown_select'} data-name={name}>
            {
                isCalendarOpen && 
                <DateRangePicker
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    ranges={selectedDates}
                    onChange={d => handleSelect(d)}
                />
            }
            <div className="dropdown_select_input" onClick={() => { setOpen(!open) }}>
                <input type="text" value={value.text} readOnly={true} name={name} data={value.value}/>
                <div className={open ? "dropdown_select__options_btn dropdown_select__options_btn_active" : "dropdown_select__options_btn"}>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.374359 0.443328C0.643686 0.174001 1.08035 0.174001 1.34968 0.443328L4.99995 4.0936L8.65022 0.443328C8.91955 0.174001 9.35621 0.174001 9.62554 0.443328C9.89487 0.712655 9.89487 1.14932 9.62554 1.41865L5.48761 5.55658C5.21828 5.82591 4.78162 5.82591 4.51229 5.55658L0.374359 1.41865C0.105031 1.14932 0.105031 0.712655 0.374359 0.443328Z" fill="#222222"/>
                    </svg>
                </div>
                <ul className={open && !isCalendarOpen ? "dropdown_select__options dropdown_select__options_active" : "dropdown_select__options"}>
                    {options.map((option, i) => 
                        option.text === 'Wählen Sie einen Zeitraum' ?
                            <li key={i} className="dropdown_select__option dropdown_select__option_date" onClick={() => {setCalendarOpen(true);}}>{option.text}</li>
                        :
                            <li key={i} className="dropdown_select__option" onClick={() => {setValue({text: option.text, value: option.value}); submit(name, option.value)}}>{option.text}</li>
                    )}
                </ul>
            </div>
        </div>
        
    )
}