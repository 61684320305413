import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'

export const ProfileBlock = ({ user, setAuth, formActive, setFormActive, setFormType, notifications }) => {
    const [isActionsOpen, setActionsOpen] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.addEventListener("click", (e) => closeActions(e));
        return () => window.removeEventListener("click", (e) => closeActions(e));
    }, [isActionsOpen]);    

    const closeActions = (e) => {
        if (!e.target.closest(".header__profile_wrapper") && isActionsOpen) {
            setActionsOpen(false);
        }
    }

    const exit = () => {
        setFormActive(true);
        setFormType("exit");
    };

    return (
        <div className="header__profile_wrapper">
            <div
                className="header__profile"
                onClick={() => {
                    setActionsOpen(!isActionsOpen);
                }}
            >
                <div className="header__profile_avatar_wrapper">
                    <img src={`/uploads/${user?.photo || 'default_profile.png'}`} alt="avatar" />
                </div>
                <div className="header__profile_name">
                    {user?.name} {user?.lastname}
                </div>
                {!!notifications && !pathname.includes('/profile') && <div className="header__profile_notifications">
                    <span>{notifications}</span>
                </div>}
                {/* <button className={isActionsOpen ? "header__profile_actions_btn header__profile_actions_btn_active" : "header__profile_actions_btn"}>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.374359 0.443328C0.643686 0.174001 1.08035 0.174001 1.34968 0.443328L4.99995 4.0936L8.65022 0.443328C8.91955 0.174001 9.35621 0.174001 9.62554 0.443328C9.89487 0.712655 9.89487 1.14932 9.62554 1.41865L5.48761 5.55658C5.21828 5.82591 4.78162 5.82591 4.51229 5.55658L0.374359 1.41865C0.105031 1.14932 0.105031 0.712655 0.374359 0.443328Z"
                            fill="#222222"
                        />
                    </svg>
                </button> */}
                <div className={isActionsOpen ? "header__profile_actions header__profile_actions_active" : "header__profile_actions"}>
                    <a href="/profile" className="header__profile_settings">
                    EINSTELLUNGEN
                    </a>
                    {[1, 2, 3, 4].includes(user?.user_type) && (
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "/admin";
                            }}
                            className="header__profile_settings"
                        >
                            REDAKTION
                        </a>
                    )}
                    <button
                        className="header__profile_exit"
                        onClick={() => {
                            exit();
                        }}
                    >
                        Abmelden
                    </button>
                </div>
            </div>
        </div>
    );
};