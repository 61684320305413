import React, {useEffect, useState} from "react";

import './index.scss';

const CookieWraning = () => {
    const [active, setActive] = useState(true);

    const acceptCookies = () => {
        localStorage.setItem('cw', '1');
        setActive(false)

    }
    const cancelCookies = () => {
        localStorage.setItem('cw', '1');
        setActive(false)
    }

    return (
        <div className={active ? "cw" : "cw cw_hidden"}>
            <div className="cw__content">
                <div className="cw__title">Herzlich willkommen!</div>
                <div className="cw__text">
                <div className="cw__text_title">Mit Werbung lesen</div>
                <p>Details zu Werbe- und Analyse-Trackern sowie zum jederzeit möglichen Widerruf finden Sie in unserer <a href="/datenschutz">Datenschutzerklärung</a> oder im <a href="/cookies">Privacy Center</a> am Ende jeder Seite.</p>
                <p>
                Tracking: Zur Verbesserung und Finanzierung unseres Webangebots arbeiten wir mit Drittanbietern zusammen. Diese Drittanbieter und wir erheben und verarbeiten personenbezogene Daten auf unseren Plattformen. Mit auf Ihrem Gerät gespeicherten Cookies, persönlichen Identifikatoren wie bspw. Geräte-Kennungen oder IP-Adressen sowie basierend auf Ihrem individuellen Nutzungsverhalten können wir und diese Drittanbieter ...
                </p>
                <p>... Informationen auf einem Gerät speichern und/oder abrufen: Für die Ihnen angezeigten Verarbeitungszwecke können Cookies, Geräte-Kennungen oder andere Informationen auf Ihrem Gerät gespeichert oder abgerufen werden (§ 25 Abs. 1 TTDSG). </p>
                <p>... Personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessungen, Erkenntnisse über Zielgruppen und Produktentwicklungen ausspielen: Anzeigen und Inhalte können basierend auf einem Profil personalisiert werden. Es können mehr Daten hinzugefügt werden, um Anzeigen und Inhalte besser zu personalisieren. Die Performance von Anzeigen und Inhalten kann gemessen werden. Erkenntnisse über Zielgruppen, die die Anzeigen und Inhalte betrachtet haben, können abgeleitet werden. Daten können verwendet werden, um Benutzerfreundlichkeit, Systeme und Software aufzubauen oder zu verbessern (Art. 6 S. 1 lit. a. und f. DSGVO).</p>
                <p>
                Sie willigen auch ein, dass Ihre Daten von Anbietern in Drittstaaten und den USA verarbeitet werden. USA-Anbieter müssen ihre Daten an dortige Behörden weitergegeben. Daher werden die USA als ein Land mit einem nach EU-Standards unzureichenden Datenschutzniveau eingeschätzt (Drittstaaten-Einwilligung) (Art. 49 Abs. 1 DSGVO). 
                </p>
                </div>
                <div className="cw__actions">
                    <button className="cw__accept" onClick={() => {acceptCookies()}}>
                    Akzeptieren und weiter
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CookieWraning